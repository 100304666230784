/* You can add global styles to this file, and also import other style files */

body {
    margin: 0;
    background-color: #580088;
    padding: 0;
}

html {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}